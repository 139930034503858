@import "styles/abstracts/_variables.scss";

.button-links {
    padding: 3rem 0;
    text-align: center;

    &--single-button {
        padding: 6rem 0;
    }

    &__button {
        display: inline-block;
        margin: .5rem;
        padding: 1.2rem 2.6rem;
        width: 20rem;
        border-radius: 2.5rem;
        text-align: center;
        text-transform: uppercase;
        background-color: $color-white;
        text-decoration: none;
        color: $color-grey-dark;
        letter-spacing: 1px;
    }
}