@import "styles/abstracts/_variables.scss";

.projects {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
    justify-items: center;
}

@media only screen and (min-width: $screen-sm-min) {
    .projects {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
    }
}

@media only screen and (min-width: $screen-md-min) {
    .projects {
        grid-column-gap: 4rem;
    }
}

@media only screen and (min-width: $screen-lg-min) {
    .projects {
        grid-template-columns: 1fr 1fr 1fr;
    }
}