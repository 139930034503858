@import "styles/abstracts/_variables";
@import "styles/base/_animations";

.info-dropdown { 
    display: none;

    &--selected {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0; 
        background-image: linear-gradient(to right bottom,  $color-primary-light, $color-primary-dark);
        z-index: $z-index-md;
        animation: slideRight .8s ease-in-out;
    }


    &__logo {
        position: fixed;
        top: 1.2rem;
        left: 1.8rem;
    }

    &__close-button {
        position: fixed;
        top: .2rem;
        right: 1.8rem;
        font-size: 4rem;
        color: $color-white;

        &:hover  {
            cursor: pointer;
        }
    }
}