@import "styles/abstracts/_variables.scss";

$flip-card-height: 550px;

.flip-card {
    position: relative;
    width: 100%;
    max-width: 38rem;
    height: $flip-card-height;
    perspective: 1600px;
    cursor: pointer;

    &__face {
        background-color: rgba($color-white, .90);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        left: 0;
        height: $flip-card-height;
        transition: all 1s ease;
        backface-visibility: hidden;
        box-shadow: 0 1rem 2rem rgba($color-black, .2);
        border-radius: $border-radius-sm;

        &--back {
            background-image: linear-gradient(to bottom right, $color-primary-light, $color-primary-dark);
            transform: rotateY(180deg);
        }
    }

    &--rotated &__face--front {
        transform: rotateY(-180deg);
    }

    &--rotated &__face--back {
        transform: rotateY(0deg);
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .flip-card {
        cursor: auto;

        &:hover &__face--front {
            transform: rotateY(-180deg);
        }

        &:hover &__face--back {
            transform: rotateY(0deg);
        }
    }
}