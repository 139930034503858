@import "styles/abstracts/_variables";

.section {
    &:nth-child(2n - 1) {
        background-size: cover;
        background-position: center;
    }

    &__header {
        background-image: linear-gradient(to right, rgba($color-primary-light, .6), rgba($color-primary-dark, .6)), 
            url("./img/header-photo.jpg");
        height: 95vh;
    }

    &__interests {
        min-height: 50rem;
        background-image: linear-gradient(to right, rgba($color-primary-light, .6), rgba($color-primary-dark, .6)),
        url("./img/view.jpg");
    }

    &__education {
        min-height: 50rem;
        background-image: linear-gradient(to right, rgba($color-primary-light, .6), rgba($color-primary-dark, .6)),
        url("./img/surfing.jpg");
    }

    &__connect {
        background-color: $color-primary;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .section {
        &__header {
            background-image: linear-gradient(to right, rgba($color-primary-light, .6), rgba($color-primary-dark, .6)), 
                url("./img/header-photo-lg.jpg");
            background-position: left;
        }
    }
}