@import "styles/abstracts/_variables";

.interest-card {
    width: 100%;
    height: 400px;

    perspective: 1600px;
    position: relative;

    &__face {
        cursor: pointer;
        position: absolute;
        transition: all 1s ease;
        padding: 2rem 2rem 3rem;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        backface-visibility: hidden;
        border-radius: $border-radius-sm;
        text-align: center;
        box-shadow: $box-shadow-basic;

        &--front {
            background-color: rgba($color-white, .90);
        }

        &--back {
            color: $color-white;
            transform: rotateY(180deg);
            background-image: linear-gradient(to bottom right, $color-secondary-light, $color-secondary-dark);
        }
    }

    &--rotated &__face--front {
        transform: rotateY(-180deg);
    }

    &--rotated &__face--back {
        transform: rotateY(0deg);
    }

    &__icon {
        margin-bottom: 1.5rem;
        font-weight: 900;
        font-size: 9rem;
        background-image: linear-gradient(to right, rgba($color-secondary-light, .7), rgba($color-secondary-dark, .7));
        -webkit-background-clip: text;
        color: transparent;
    }

    &__title {
        font-size: 2rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: .5rem;
        letter-spacing: .2rem;
    }

    &__details {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        text-align: justify;
    }

    &__list {
        list-style: none;
        width: 92%;
        margin: 0 auto;
    }

    &__list-item {
        text-align: center;
        font-size: 1.4rem;
        padding: .5rem;
        cursor: pointer;
        border-top: 1px solid $color-white;
    }

    &__list-item-link {
        display: block;
        color: $color-white;
        text-decoration: none;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .interest-card {
        &__face {
            cursor: auto;
        }

        &:hover &__face--front {
            transform: rotateY(-180deg);
        }

        &:hover &__face--back {
            transform: rotateY(0deg);
        }
    }
}