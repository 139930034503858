@import "styles/abstracts/_variables.scss";

.tech-stack {
    &__title {
        text-align: center;
        text-transform: uppercase;
        padding: 2rem 0 1rem;
        letter-spacing: .5rem;
        font-size: 2rem;
        color: $color-white;
    }

    &__list {
        padding: 0 3rem;

        ul {
            list-style: none;
            margin: 0 auto;

            li {
                text-align: center;
                color: $color-white;

                letter-spacing: .2rem;
                font-size: 1.4rem;
                padding: .5rem;

                border-top: 1px solid $color-white;

                &:last-child {
                    border-bottom: 1px solid $color-white;
                }
            }
        }
    }
}