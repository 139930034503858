@import "styles/abstracts/_variables.scss";

.header {
    position: relative;
    height: 100%;

    &__title-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }
}

.header-primary {
    color: white;
    text-transform: uppercase;

    &--main {
        display: inline-block;
        font-size: 3.2rem;
        font-weight: 400;
        letter-spacing: .6rem;
        margin-bottom: 1rem;

        animation: moveInLeft 1s ease-out;
    }
    
    &--sub {
        display: block;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .5rem;

        animation: moveInRight 1s ease-out 1s backwards;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .header-primary {
        &--main {
            font-size: 4.8rem;
            letter-spacing: 1.4rem;
            margin-bottom: 2rem;
        }
        
        &--sub {
            font-size: 2.2rem;
            letter-spacing: 1.3rem;
        }
    }
}

@media only screen and (min-width: $screen-md-min) {
    .header-primary {
        &--main {
            font-size: 6rem;
            letter-spacing: 1.7rem;
            margin-bottom: 2rem;
        }
        
        &--sub {
            font-size: 2.4rem;
            letter-spacing: 1.76rem;
        }
    }
}