@import "styles/abstracts/_variables";

.logo {
    &__image {
        height: 3.5rem;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .logo {
        &__image {
            height: 4.5rem;
        }
    }
}