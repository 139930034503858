@import "styles/abstracts/_variables";

.info-icon {
    width: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:hover  {
        cursor: pointer;
    }

    &__bar {
        height: .6rem;
        background-color: $color-white;
        border-radius: .1rem;

        &:not(:last-child) {
            margin-bottom: .4rem;
        }
    }
}