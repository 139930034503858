body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $color-grey-dark;
}



.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
}