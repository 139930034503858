@import "styles/abstracts/_variables.scss";

.timeline-item { 
    position: relative;
    margin: $timeline-item-vertical-spacing 0;
    width: 100%;
    background-color: $color-white;
    box-shadow: $box-shadow-basic;
    border-radius: $border-radius-sm;

    &::after {
        content: "";
        position: absolute;
        background-color: rgba($color-primary-light, .6);
        top: $timeline-arrow-vertical-offset;
        left: -2rem;
        width: 2rem;
        height: 3rem;
        clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }

    &__header {
        position: relative;
        background-size: cover;
        background-position: center;
        background-blend-mode: screen;
        border-top-right-radius: $border-radius-sm;
        border-top-left-radius: $border-radius-sm;

        
        &--autodata {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/autodata.jpg);
        }

        &--autodata-intern {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/internship.jpg);
        }

        &--work-study {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/work-study.jpg);
        }
    }

    &__date-range {
        display: inline-block;
        padding: 1rem;
        margin-bottom: 1.5rem;
        color: $color-white;
        font-size: 1.6rem;
        text-transform: uppercase;
        background-image: linear-gradient(to right bottom,  rgba($color-secondary-light, .85), rgba($color-secondary-dark, .85));
        border-top-left-radius: $border-radius-sm;
    }

    &__job-description {
        color: $color-white;
        text-transform: uppercase;
        padding: 1rem;
    }

    &__job-title {
        display: block;
        font-size: 2.8rem;
        letter-spacing: .3rem;
        margin-bottom: .5rem;
        font-weight: 500;
    }

    &__company {
        display: block;
        font-size: 2rem;
        letter-spacing: .1rem;
        font-weight: 500;
    }

    &__job-details {
        list-style: none;
        margin: 0 auto;
        padding: 1rem 2rem;
        font-size: 1.6rem;
    }

    &__job-detail {
        padding: 1rem 0rem;
        
        &:not(:first-child) {
            border-top: 1px solid $color-grey;
        }
    }
}