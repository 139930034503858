@import "styles/abstracts/_variables.scss";

.card-front {
    &__picture {
        background-size: cover;
        background-position: center;
        background-blend-mode: screen;
        height: 25rem;
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;

        &--job-site-manager-app {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/work-site.jpg);
        }

        &--tourny {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/tennis-tournament.jpg);
        }

        &--portfolio-v2 {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/coding.jpg);
        }

        &--portfolio-v1 {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/portfolio-v1.jpg);
        }

        &--trades-watch {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/stocks.jpg);
        }
    }

    &__heading {
        font-size: 2.8rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: right;
        color: $color-white;
        position: absolute;
        top: 15rem;
        right: 2rem;
        width: 80%;
    }

    &__heading-span {
        padding: 1rem 1.5rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background-image: linear-gradient(to right bottom, rgba($color-secondary-light, .85), rgba($color-secondary-dark, .85));
    }

    &__details {
        padding: 1rem 1rem;
        font-size: 1.6rem;
        text-align: justify;
    }

    &__paragraph {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}

@media only screen and (min-width: $screen-md-min) {
    .card-front {
       .details {
            padding: 2rem;
       }

       &__paragraph {
        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    }
}