*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    box-sizing: border-box;
    min-width: 32rem;
}

html {
    // The default browser font size is 16px, this set it to 10px
    // Setting font size to 10pxs makes using rem straightforward
    font-size: 62.5%;
}