// COLORS
$color-grey-dark: #777;
$color-grey: #ccc;
$color-grey-light: #eee;

$color-white: #fff;

$color-black: #000;

$color-primary-light: #4fa0e2;
$color-primary: #1d80d1;
$color-primary-dark: #032564;

$color-secondary-light: #ffa500;
$color-secondary: #FF8C00;
$color-secondary-dark: #ff6500;

// FONTS
$default-font-size: 1.6rem;

// Other
$border-radius-sm: .3rem;
$box-shadow-basic: 0 1rem 2rem rgba(#000, .3);

// -----------------------------------------
// Components
// -----------------------------------------
// Timeline
$timeline-marker-height: 3rem;
$timeline-item-vertical-spacing: 4rem;
$timeline-arrow-vertical-offset: 3rem;

// SCREEN SIZES
$screen-xs-min: 320px;
$screen-sm-min: 576px; // Portrait phones
$screen-md-min: 768px; // Tablets
$screen-lg-min: 992px; // Desktop
$screen-xl-min: 1200px; // Large desktop

// z-index
$z-index-sm: 1000;
$z-index-md: 2000;
$z-index-lg: 3000;