@import "styles/abstracts/_variables";

.footer {
    display: flex;
    flex-direction: column;

    &__connect-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-white;
        font-size: 1.6rem;
        margin-bottom: 2.5rem;
    }

    &__connect-title {
        text-transform: uppercase;
        font-size: 2rem;
        letter-spacing: .4rem;
        margin-bottom: .5rem;
    }

    &__link {
        color: $color-white;
        cursor: pointer;
    }

    &__credits-title {
        text-transform: uppercase;
        font-size: 1.6rem;
        letter-spacing: .2rem;
        margin-bottom: .3rem;
    }

    &__credits {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-white;
        font-size: 1.6rem;
    }

    &__credit {
        font-size: 1.4rem;
        text-align: center;
    }
}

@media only screen and (min-width: $screen-md-min) {
    .footer {
        flex-direction: row;
        justify-content: space-between;

        &__connect-info {
            align-items: flex-start;
            margin-bottom: 0;
        }

        &__credits {
            align-items: flex-end;
        }
    }
}