@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-75px);
    }

    80% {
        transform: translateX(15px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(75px);
    }

    80% {
        transform: translateX(-15px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideRight {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(0);
    }
}