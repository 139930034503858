@import "styles/abstracts/_variables.scss";

.timeline {
    max-width: 120rem;
    margin: 0 auto;

    &__section {
        display: flex;
        align-items: stretch;

        &:first-child {
            .timeline-item {
                margin-top: 1rem;
            }

            .timeline__bar {
                &::after {
                    border-color: $color-secondary;
                    top: calc(1rem + #{$timeline-arrow-vertical-offset});
                }
            }
        }

        &:last-child {
            .timeline-item {
                margin-bottom: 1rem;
            }
        }
    }

    &__bar {
        flex: 0 0 auto;
        position: relative;
        width: .4rem; 
        margin-right: 4rem;
        margin-left: $timeline-marker-height/2;
        background-color: rgba($color-primary-light, .6);

        &::after {
            content: "";
            position: absolute;
            top: $timeline-item-vertical-spacing + $timeline-arrow-vertical-offset;
            left: -($timeline-marker-height/2 - .2rem);
            border: $timeline-marker-height/2 solid $color-primary;
            border-radius: 50%;
        }
    }
}