@import "styles/abstracts/_variables.scss";

$column-gutter: 5rem;

.technologies {
    &__section {
        margin-bottom: 6rem;
        padding: 3rem 2rem 5rem;
        background-color: $color-white;
        box-shadow: 0 1rem 2rem rgba($color-black, .3);
        border-radius: .3rem;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title-box {
        position: relative;
        text-align: left;
        border-bottom: .3rem solid $color-secondary;
    }

    &__title {
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 1rem;
        width: 100%;
        text-align: center;
        font-size: 2.4rem;
        text-transform: uppercase;
        letter-spacing: .7rem;
    }

    &__list {
        display: block;
        width: 100%;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .technologies{
        &__title {
            text-align: left;
        }

        &__list {
            display: flex; 
            flex-wrap: wrap;
        }
    }

    .technology {
        width: calc((100%/2) - (#{$column-gutter}/2));

        &:nth-child(2n - 1) {
            margin-right: $column-gutter;
        }
    }
}

@media only screen and (min-width: $screen-lg-min) {
    .technology {
        width: calc((100%/3) - ((2 * #{$column-gutter})/3));
        margin-right: 0;

        &:nth-child(2n - 1) {
            margin-right: 0;
        }

        &:not(:nth-child(3n)) {
            margin-right: $column-gutter;
        }
    }
}