@import "styles/abstracts/_variables.scss";

$vertical-padding: 6rem;
$vertical-overlap: 15rem;

.section {
    margin-top: -$vertical-overlap;
    padding: ($vertical-overlap + $vertical-padding) 1rem; // Takes into account the negative vertical margins
    clip-path: polygon(0 #{$vertical-overlap}, 100% 0, 100% calc(100% - #{$vertical-overlap}), 0 100%);

    &:nth-child(2n) {
        background-color: $color-grey-light;
    }

    &:first-child {
        margin-top: 0;
        padding-top:$vertical-padding;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$vertical-overlap}), 0 100%);
    }

    &:last-child {
        padding-bottom: $vertical-padding;
        clip-path: polygon(0 #{$vertical-overlap}, 100% 0, 100% 100%, 0 100%);
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .section {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media only screen and (min-width: $screen-md-min) {
    .section {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
