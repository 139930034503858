@import "styles/abstracts/_variables.scss";

.technology {
    display: block;
    min-height: 6.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    color: $color-grey-dark;

    border-bottom: 1px solid $color-grey;
}

@media only screen and (min-width: $screen-sm-min) {
    .technology {
        justify-content: left;
    }
}