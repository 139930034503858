@import "styles/abstracts/_variables";

.education {
    &__card {
        background-color: rgba($color-white, .9);
        border-radius: $border-radius-sm;
        box-shadow: $box-shadow-basic;

        &:not(:last-child) {
            margin-bottom: 4rem;
        }
    }

    &__header {
        position: relative;
        background-size: cover;
        background-position: center;
        background-blend-mode: screen;
        border-top-right-radius: $border-radius-sm;
        border-top-left-radius: $border-radius-sm;

        &--school {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/school.jpg);
        }

        &--css {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/front-end.jpg);
        }

        &--deploy {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark), url(./img/deploy.jpg);
        }
    }

    &__sub-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    &__label {
        display: inline-block;
        padding: 1rem;
        color: $color-white;
        font-size: 1.8rem;
        letter-spacing: .3rem;
        text-transform: uppercase;
    }

    &__type {
        display: flex;
        align-items: center;
        width: 20rem;
        background-image: linear-gradient(to right bottom, $color-secondary-light, $color-secondary-dark);
        border-top-left-radius: $border-radius-sm;

        &--course {
            background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
        }
    }

    &__date-range {
        font-weight: 700;
        text-align: right;
    }

    &__degree-description {
        color: $color-white;
        text-transform: uppercase;
        padding: 1rem;
    }

    &__degree-title {
        display: block;
        font-size: 2.8rem;
        letter-spacing: .3rem;
        margin-bottom: .5rem;
        font-weight: 500;
    }

    &__degree-sub-title {
        display: block;
        font-size: 2rem;
        letter-spacing: .1rem;
        font-weight: 500;
    }

    &__content {
        padding: 2rem;
    }

    &__school-name {
        font-size: 1.8rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        font-weight: 700;
    }

    &__achievements {
        display: block;
        font-size: 1.6rem;
    }
}