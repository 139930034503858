@import "styles/abstracts/_variables";

.interests {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 4rem;
    grid-column-gap: 2rem;
}

@media only screen and (min-width: $screen-md-min) {
    .interests {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: $screen-lg-min) {
    .interests {
        grid-column-gap: 4rem;
    }
}