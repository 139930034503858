@import "styles/abstracts/_variables";

.info-bar {
    position: fixed;
    width: 100vw;
    min-width: $screen-xs-min;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    z-index: $z-index-sm;
    transition: box-shadow .5s ease-out, background .5s ease-out;

    &--hidden {
        display: none;
    }
    
    &--affixed {
        box-shadow: $box-shadow-basic;
        opacity: .96;
        background-color: $color-primary;
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .info-icon {
        display: none;
    }
}