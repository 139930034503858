@import "styles/abstracts/_variables";

.section-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--horizontal {
        display: none;
    }

    &__button {
        padding: 2rem 0;
        height: 6rem;
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        color: $color-grey;
        letter-spacing: .5rem;
        font-size: 2rem;

        &--selected, &:hover {
            color: $color-white;
            cursor: pointer;
            border-bottom: 1px solid $color-secondary;
        }
    }
}

@media only screen and (min-width: $screen-sm-min) {
    .section-list {
        &--horizontal {
            display: block;
        }

        &__button {
            padding: 0;
            height: 3rem;
            display: inline-block;
            letter-spacing: .2rem;
            font-size: $default-font-size;
        
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
        
            &:hover  {
                color: $color-white;
                cursor: pointer;
                border-bottom: 1px solid $color-secondary;
            }
        }
    }
}

@media only screen and (min-width: $screen-md-min) {
    .section-list {
        &__button {
            letter-spacing: .5rem;
        
            &:not(:last-child) {
                margin-right: 4.5rem;
            }
        }
    }
}